<template>
  <v-app>
    <ClientOnly>
      <SiteNavigationLeftDrawer />
      <SiteBottomNavigation />
    </ClientOnly>
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>
<script setup></script>
